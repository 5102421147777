<template>
  <v-content>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="8">
          <v-card class="elevation-12">
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-row>
                  <v-col cols="12" md="8">
                    <v-card-text class="mt-12">
                      <h1
                        class="text-center display-2 red--text text--accent-3"
                      >
                        Sign in to Megaswords
                      </h1>
                      <div class="text-center mt-4">
                        <v-btn class="mx-2" fab color="black" outlined @click="socialLogin('facebook')">
                          <v-icon>fab fa-facebook-f</v-icon>
                        </v-btn>

                        <v-btn class="mx-2" fab color="black" outlined @click="socialLogin('google')">
                          <v-icon>fab fa-google-plus-g</v-icon>
                        </v-btn>
                        <v-btn class="mx-2" fab color="black" outlined @click="socialLogin('twitter')">
                          <v-icon>fab fa-twitter</v-icon>
                        </v-btn>
                      </div>
                      <h4 class="text-center mt-4">Ensure your email for registration</h4>
                      <v-form>
                        <v-text-field
                          v-model="form.email"
                          label="Email"
                          name="Email"
                          prepend-icon="email"
                          type="text"
                          color="red accent-3"
                        />

                        <v-text-field
                          v-model="form.password"
                          id="password"
                          label="Password"
                          name="password"
                          prepend-icon="lock"
                          type="password"
                          color="red accent-3"
                        />
                      </v-form>
                      <h3 class="text-center mt-4">Forgot your password ?</h3>
                    </v-card-text>
                    <div class="text-center mt-3 pb-2">
                      <v-btn rounded color="red accent-3" dark @click="signIn">SIGN IN</v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4" class="red accent-3">
                    <v-card-text class="white--text mt-12">
                      <h1 class="text-center display-1">Hello, Friend!</h1>
                      <h5
                        class="text-center"
                      >Enter your personal details and start journay with us</h5>
                    </v-card-text>
                    <div class="text-center pb-2">
                      <v-btn rounded outlined dark @click="step++">SIGN UP</v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item :value="2">
                <v-row class="fill-height">
                  <v-col cols="12" md="4" class="red accent-3">
                    <v-card-text class="white--text mt-12">
                      <h1 class="text-center display-1">Welcome Back!</h1>
                      <h5
                        class="text-center"
                      >To Keep connected with us please login with your personnel info</h5>
                    </v-card-text>
                    <div class="text-center">
                      <v-btn rounded outlined dark @click="step--">Sign in</v-btn>
                    </div>
                  </v-col>

                  <v-col cols="12" md="8">
                    <v-card-text class="mt-12">
                      <h1 class="text-center display-2 red--text text--accent-3">Create Account</h1>
                      <h4 class="text-center mt-4">Ensure your email for registration</h4>
                      <v-form>
                        <v-text-field
                          v-model="form.username"
                          label="Username"
                          name="Username"
                          prepend-icon="account_circle"
                          type="text"
                          color="red accent-3"
                        />
                        <v-text-field
                          v-model="form.email"
                          label="Email"
                          name="Email"
                          prepend-icon="email"
                          type="text"
                          color="red accent-3"
                        />

                        <v-text-field
                          v-model="form.password"
                          id="password"
                          label="Password"
                          name="password"
                          prepend-icon="lock"
                          type="password"
                          color="red accent-3"
                        />

                        <v-text-field
                          v-model="form.password_confirmation"
                          id="password"
                          label="Password Confirmation"
                          name="password_confirmation"
                          prepend-icon="lock"
                          type="password"
                          color="red accent-3"
                        />
                      </v-form>
                    </v-card-text>
                    <div class="text-center mt-n5 pb-2">
                      <v-btn rounded color="red accent-3" dark @click="signUp">SIGN UP</v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="snackbar.status = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-content>
</template>

<script>
// http://localhost:8080/?client_id=megaswords&redirect_uri=https%3A%2F%2Fmegaswords.com%2Fsso&response_type=token
// http://localhost:8081/?client_id=megaswords&redirect_uri=http%3A%2F%2Flocalhost:8080%2Fsso&response_type=token
// http://localhost:8081/?client_id=megaswords&redirect_uri=http%3A%2F%2F192.168.0.129:8080%2Fsso&response_type=token
import * as jwt from 'jsonwebtoken';
import firebase from 'firebase';

const client_id = [
  'megaswords',
];

const redirect_uri = [
  'https://megaswords.com/sso',
  'http://localhost:8080/sso',
  'http://192.168.0.100:8080/sso'
];

export default {
  data () {
    return {
      snackbar: {
        status: false,
        text: 'Login succesfull',
        color: 'success',
      },
      snackbarText: '',
      step: 1,
      form: {
        username: null,
        email: '',
        password: '',
        password_confirmation: '',
      },
      requestInfos: {
        client_id: null,
        redirect_uri: null,
        response_type: 'token',
      }
    };
  },
  async mounted () {
    this.requestInfos = this.$router.currentRoute.query;
    if (!client_id.includes(this.requestInfos.client_id)) {
      this.$router.push('error');
    }
    if (!redirect_uri.includes(this.requestInfos.redirect_uri)) {
      this.$router.push('error');
    }
  },
  computed: {
  },
  methods: {
    async signUp () {
      if (!this.form.username) {
        this.reportSnackbar({ color: 'error', text: 'Invalid username'});
        return;
      }
      if (this.form.password.length < 6) {
        this.reportSnackbar({ color: 'error', text: 'Password is too short'});
        return;
      }
      if (this.form.password !== this.form.password_confirmation) {
        this.reportSnackbar({ color: 'error', text: 'Invalid password confirmation'});
        return;
      }
      try {
        const result = await firebase.auth().createUserWithEmailAndPassword(this.form.email, this.form.password);
        await this.createUser(result.user);
        this.successLogin(result);
      } catch (error) {
        console.error(error);
        this.reportSnackbar({ color: 'error', text: error.message});
      }
    },
    async signIn () {
      try {
        const result = await firebase.auth().signInWithEmailAndPassword(this.form.email, this.form.password);
        const user = await this.getUser(result.user.uid);
        if (!user) {
          this.createUser(result.user);
        }
        this.successLogin(result);
      } catch (error) {
        console.error(error);
        this.reportSnackbar({ color: 'error', text: error.message});
      }
    },
    async socialLogin (network = 'google') {
      let provider = new firebase.auth.GoogleAuthProvider();
      if (network === 'twitter') {
        provider = new firebase.auth.TwitterAuthProvider();
      } else if (network === 'facebook') {
        provider = new firebase.auth.FacebookAuthProvider();
      }
      try {
        const result = await firebase.auth().signInWithPopup(provider);
        const user = await this.getUser(result.user.uid);
        if (!user) {
          const userObject = {
            email: result.user.email || result.additionalUserInfo.email || '',
            uid: result.user.uid,
            displayName: result.user.displayName,
          };
          this.createUser(userObject);
        }
        this.successLogin(result);
      } catch (error) {
        console.error(error);
        this.reportSnackbar({ color: 'error', text: error.message});
      }
    },
    async getUser (uid) {
      const user = await firebase.firestore().collection('users').doc(uid).get();
      return user.exists === true ? user : null;
    },
    async createUser (userObject) {
      try {
        await firebase.firestore().collection('users').doc(userObject.uid).set({
          email: this.form.email || userObject.email,
          username: this.form.username || userObject.displayName,
        });
      } catch (error) {
        console.error(error);
        this.reportSnackbar({ color: 'error', text: error.message });
      }
    },
    successLogin (data) {
      const { credential, user } = data;
      const userData = {
        uid: user.uid,
        email: user.email,
        credential: credential,
      };
      const token = jwt.sign(userData, 'banana');
      const url = `${this.requestInfos.redirect_uri}?${this.requestInfos.response_type}=${token}`;
      this.reportSnackbar({ color: 'success', text: 'Login successfully' });
      window.location.href = url;
    },
    reportSnackbar (snackbar) {
      this.snackbar = {
        ...{
          status: true,
          text: 'Hello',
          color: 'info',
        },
        ...snackbar
      }
    }
  },
};
</script>

<style scoped>
.login-form {
    width: 340px;
    margin: 30px auto;
    font-size: 15px;
}
.login-form form {
    margin-bottom: 15px;
    background: #f7f7f7;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    padding: 30px;
}
.login-form h2 {
    margin: 0 0 15px;
}
.login-form .hint-text {
    color: #777;
    padding-bottom: 15px;
    text-align: center;
    font-size: 13px;
}
.form-control, .btn {
    min-height: 38px;
    border-radius: 2px;
}
.login-btn {
    font-size: 15px;
    font-weight: bold;
}
.or-seperator {
    margin: 20px 0 10px;
    text-align: center;
    border-top: 1px solid #ccc;
}
.or-seperator i {
    padding: 0 10px;
    background: #f7f7f7;
    position: relative;
    top: -11px;
    z-index: 1;
}
.social-btn .btn {
    margin: 10px 0;
    font-size: 15px;
    text-align: left;
    line-height: 24px;
}
.social-btn .btn i {
    float: left;
    margin: 4px 15px  0 5px;
    min-width: 15px;
}
.input-group-addon .fa{
    font-size: 18px;
}
</style>
