<template>
  <div class="container-animation">
    <h1 style="color: #960914;">
      Error
    </h1>
    <img
      id="deadAnimation"
      src="@/assets/dead.gif"
      loop="infinite"
      alt="megaswords player dead animation"
      width="100px"
      height="100px"
    >
    <v-btn
      to="/"
      rounded
      color="#960914"
    >
      Back to site
    </v-btn>
  </div>
</template>

<script>

export default {
  head: {
    title: 'Megaswords',
  },
};
</script>
<style lang="scss">
// Grid (Bootstrap overrides)
// ------------------------------------
#__nuxt,
#__layout {
  height: 100%;
}

.container-animation {
  background-color: black;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
