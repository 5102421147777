import Vue from 'vue';
import firebase from 'firebase/app';
import App from './App.vue';
import router from './routes/index';
import store from './store';
import vuetify from './plugins/vuetify';
import dotenv from 'dotenv';

import firebaseConfig from './config/firebase';
dotenv.config();

Vue.config.productionTip = false;

firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore();

firebase.auth().onAuthStateChanged(user => {
  store.dispatch('fetchUser', user);
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
