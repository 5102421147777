import Vue from 'vue';
import Router from 'vue-router';
import Login from '../components/Login';
import ErrorVue from '../components/Error';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
    },
    {
      path: '/error',
      name: 'error',
      component: ErrorVue,
    },
  ],
});

export default router;
